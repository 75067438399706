import React, { Component } from 'react';
import { I18nextProvider, withTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18next from './config';

export function withTrans(WrappedComponent) {
  WrappedComponent = withTranslation()(WrappedComponent);

  return class extends Component {
    render() {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }
}


// https://medium.com/@makerspirit/step-by-step-guide-to-use-i18next-in-gatsby-app-19d21f2b723d
// export function withTrans(WrappedComponent) {
//   WrappedComponent = withTranslation()(WrappedComponent);
//
//   // const cookiesLocale = Cookies.get(process.env.GATSBY_COOKIE_LOCALE);
//
//   return class extends Component {
//     render() {
//       return (
//         <I18nextProvider i18n={i18next}>
//           <WrappedComponent
//             {...this.props}
//             // language={i18next.language ?? cookiesLocale ?? 'fr'}
//           />
//         </I18nextProvider>
//       );
//     }
//   };
// }
