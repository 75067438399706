// src/gatsby-plugin-apollo/client.js
import fetch from 'isomorphic-fetch';
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
  ssrMode: true,
  cache: new InMemoryCache(),
  link: new HttpLink({
    // uri: process.env.REACT_APP_GRAPHQL_URI,
    uri: process.env.GATSBY_GRAPHQL_URI,
    fetch,
  }),
});

export default client;
