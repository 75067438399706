import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'; // if you are using with React

i18next
  .use(LanguageDetector)
  .use(initReactI18next) // if you are using with React
  .init({
    languages:  ['fr', 'de'],
    supportedLngs:  ['fr', 'de'],
    fallbackLng: 'fr',
    detection: {
      order: ['htmlTag', 'path', 'navigator', 'cookie'],
      lookupFromPathIndex: 0 // This will look for the language code in the URL prefix, e.g., `/fr/`
    },
    resources: {
      fr: {
        translations: require('../assets/translations/translations.fr.json'),
      },
      de: {
        translations: require('../assets/translations/translations.de.json'),
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    // debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      useSuspense: true,
    },
});

// i18next.languages = ['fr', 'en'];
i18next.languages = ['fr', 'de'];

export default i18next;
