exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../../../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-strapi-club-locale-clubs-jsx": () => import("./../../../src/pages/{StrapiClub.locale}/clubs.jsx" /* webpackChunkName: "component---src-pages-strapi-club-locale-clubs-jsx" */),
  "component---src-pages-strapi-personal-trainer-locale-personal-trainers-jsx": () => import("./../../../src/pages/{StrapiPersonalTrainer.locale}/personal-trainers.jsx" /* webpackChunkName: "component---src-pages-strapi-personal-trainer-locale-personal-trainers-jsx" */),
  "component---src-templates-category-category-detail-category-detail-jsx": () => import("./../../../src/templates/category/categoryDetail/categoryDetail.jsx" /* webpackChunkName: "component---src-templates-category-category-detail-category-detail-jsx" */),
  "component---src-templates-category-category-listing-categories-jsx": () => import("./../../../src/templates/category/categoryListing/categories.jsx" /* webpackChunkName: "component---src-templates-category-category-listing-categories-jsx" */),
  "component---src-templates-category-class-page-class-page-jsx": () => import("./../../../src/templates/category/classPage/classPage.jsx" /* webpackChunkName: "component---src-templates-category-class-page-class-page-jsx" */),
  "component---src-templates-class-schedule-all-clubs-jsx": () => import("./../../../src/templates/class-schedule/allClubs.jsx" /* webpackChunkName: "component---src-templates-class-schedule-all-clubs-jsx" */),
  "component---src-templates-club-club-detail-jsx": () => import("./../../../src/templates/club/clubDetail.jsx" /* webpackChunkName: "component---src-templates-club-club-detail-jsx" */),
  "component---src-templates-free-trial-free-trial-jsx": () => import("./../../../src/templates/free-trial/free-trial.jsx" /* webpackChunkName: "component---src-templates-free-trial-free-trial-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../../../src/templates/homePage.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-hub-clubs-clubs-jsx": () => import("./../../../src/templates/hub/clubs/clubs.jsx" /* webpackChunkName: "component---src-templates-hub-clubs-clubs-jsx" */),
  "component---src-templates-hub-personal-trainers-personal-trainers-jsx": () => import("./../../../src/templates/hub/personal-trainers/personalTrainers.jsx" /* webpackChunkName: "component---src-templates-hub-personal-trainers-personal-trainers-jsx" */),
  "component---src-templates-membership-membership-jsx": () => import("./../../../src/templates/membership/membership.jsx" /* webpackChunkName: "component---src-templates-membership-membership-jsx" */),
  "component---src-templates-personal-trainer-coach-jsx": () => import("./../../../src/templates/personal-trainer/coach.jsx" /* webpackChunkName: "component---src-templates-personal-trainer-coach-jsx" */),
  "component---src-templates-sitemap-jsx": () => import("./../../../src/templates/sitemap.jsx" /* webpackChunkName: "component---src-templates-sitemap-jsx" */),
  "component---src-templates-strapi-page-jsx": () => import("./../../../src/templates/strapi-page.jsx" /* webpackChunkName: "component---src-templates-strapi-page-jsx" */),
  "component---src-templates-week-trial-week-trial-jsx": () => import("./../../../src/templates/week-trial/week-trial.jsx" /* webpackChunkName: "component---src-templates-week-trial-week-trial-jsx" */)
}

