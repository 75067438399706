import React from 'react'

const defaultState = {
  cart: null,
  updateCart: () => {},
}

const CartContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
// const supportsDarkMode = () =>
//   window.matchMedia('(prefers-color-scheme: dark)').matches === true

class CartProvider extends React.Component {
  state = {
    cart: null,
  }

  updateCart = (cart) => {
    this.setState({ cart })
  }

  componentDidMount() {
  }

  render() {
    const { children } = this.props
    const { cart } = this.state
    return (
      <CartContext.Provider
        value={{
          cart,
          updateCart: this.updateCart,
        }}
      >
        {children}
      </CartContext.Provider>
    )
  }
}

export default CartContext

export { CartProvider }
